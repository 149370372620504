<template>
    <div class="cs-mosaic-motion-module-block cs-block" :class="`${styleClass}${hasBg}`">
        <div class="cs-block-base" >
            <div class="container">
                <div class="text"> 
                    <h2 class="cs-title">
                        <ColorSplit :data="data.title" />
                    </h2>
                    <h3 class="cs-sub-title" v-if="data.subTitle">{{data.subTitle}}</h3>
                    <span class="cs-text" v-html="data.text"></span>
                </div>
                
                <div class="slider-container">
                    
                    <Slider :data="data.cards" v-slot="item">
                        <CSButton :data="item.slide.url">
                            <div class="up-side">
                                <h4>
                                    <ColorSplit :data="item.slide.title" />
                                </h4>
                            </div>
                            <div class="down-side">
                                <img :src="item.slide.image">
                                <span class="cs-text" v-html="item.slide.text"></span>
                            </div>
                        </CSButton>

                    </Slider>

                </div>

            </div>
        </div>
    </div>
</template>

<script>

    import {computed} from '../libs/common-fn';

export default {
        name: 'MosaicMotionModule',
        components: { 
            Slider: () => import("../blocks_components/Slider.vue"),
            ColorSplit: () => import("../components/CSUtilsColorSplit.vue"),
            CSButton: () => import("../components/CSButton.vue"),
        },
        props: {
            data: {
                type: Object,
            default: () => {},
            },
        },
        data() {
            return {}
        },
        methods: {},
        computed: {
            ...computed('MosaicMotionModule'),
            hasBg() {
                if (this.data.style == 'color') return ' has-background';

                return '';
            }
        }
    };
</script>

<style scoped lang="scss">
    @import '../styles/main.scss';
    .cs-block.cs-mosaic-motion-module-block{
       &.cs-style- {
        &color {
            @include cs-block-color;
            .cs-block-base {
                .text{
                    color:white;
                    h3{
                        color: $sub_title_color;
                    }
                }

                .slider-container {
                    ::v-deep {
                        .cs-button {
                            .down-side {
                                .cs-text {
                                    * {
                                        color: black !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &light {
            @include cs-block-light;
        }

        &dark {
            @include cs-block-dark;
            .cs-block-base .text{
                h3{
                    color: $sub_title_color_dark;
                }
            }
        }
    }


    .cs-block-base .container>.text{
        text-align:center;
    }
    

    .slick-slide:nth-child(1 of div.slick-active){
        .up-side{
            opacity: 1;
        }
        .down-side{
            opacity:0;
        }
        a:hover{
            .up-side{
                opacity: 0;
            }
            .down-side{
                opacity:1;
            }
        }
    }
    .slider-container{
        margin-top: 30px;
        background: #e4eef7;
        border-radius:15px;
        
        ::v-deep .cs-button{
            position: relative;
            cursor: pointer;
            &:after {
                content: "";
                padding-bottom: 100%;
                display: block;
            }
            >div {
                position: absolute;
                width: 100%;
                height: 100%;
                overflow: hidden;
            }
            &:hover{
                .up-side{
                    opacity: 1;
                }
                .down-side{
                    opacity:0;
                }
            }
            .up-side {
                background: linear-gradient(16deg, #3979B7 -1.21%, #0D263E 94.62%);
                opacity:0;
                border-radius: 15px;
                padding: 5px;

                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;

                h4 {
                    color: white;
                    @media (max-width: 992px) {
                        font-size: 1rem;
                    }
                }
            }
            .down-side {
                opacity:1;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                text-align: center;
                text-decoration: none;
                color: black;
                font-weight:400;
                img {
                    width: 60%;
                    height: 60%;
                    object-fit: cover;
                    margin-bottom:5px;
                }
                span{
                    @media (max-width: 990px) {
                        font-size: 0.9rem;
                    }
                }
            }
        }
    }
}

</style>
